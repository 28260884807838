<template>
  <div class="list-menu-item-title" :class="!item.statu && 'disabled'" data-toggle="sub-menu">
    <div class="d-flex align-items-center">
      <i class="fad fa-sort-up move-el draggable-task-handle pr-2" />
      <div>
        <span class="d-block font-weight-normal p-0">{{ item.icerik[defaultDil].baslik }}</span>
        <div class="d-flex align-items-center">
          <b-badge
            class="font-weight-light p-1 mr-1 rounded-0"
            style="font-size: 8px; text-align: center"
            :variant="langColor(item.icerik[dil.lang].baslik)"
            v-for="(dil, index) in diller"
            :key="index"
          >
            {{ dil.lang }}
          </b-badge>
          <b-badge
            class="font-weight-light p-1 mr-1 rounded-0"
            style="font-size: 12px; text-align: center"
            variant="info"
            v-b-tooltip.hover.top="'Araç Kapasite'"
          >
            <i class="fad fa-users"></i> {{ item.kapasite.min }} / {{ item.kapasite.max }}
          </b-badge>
          <b-badge
            class="font-weight-light p-1 mr-1 rounded-0"
            style="font-size: 12px; text-align: center"
            variant="warning"
            v-b-tooltip.hover.top="'Bağaj Kapasite'"
          >
            <i class="fad fa-suitcase"></i> {{ item.bagaj }}
          </b-badge>
        </div>
      </div>
    </div>
    <b-button-group>
      <b-button size="sm" variant="warning" tooltip="düzenle" class="rounded-0" @click="$emit('handlerUpdate', item)">
        <i class="fad fa-edit"></i>
      </b-button>
      <b-button size="sm" variant="danger" class="rounded-0" @click="handlerRemove(item.k_no)">
        <i class="fad fa-trash"></i>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Swal from 'sweetalert2';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.langColor = (value) => {
      return value != '' && value != null ? 'success' : 'danger';
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Firmaya Ait Tüm Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('transferAracSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
            } else {
              toast.error(res.data.message, { position: 'bottom-left' });
              context.emit('show', false);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
