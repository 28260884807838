<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Bölge İşlemleri'"
    :total="rows.length"
    :type="type"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
    @handlerClose="type = $event"
  >
    <template v-slot:leftBody>
      <nav class="nav-list-menu">
        <ul class="list-menu">
          <b-alert variant="warning" show class="text-center m-3" v-if="!rows.length">
            <h4 class="alert-heading">Bilgi!</h4>
            <p>Henüz Kayıt Bulunmamaktadır.</p>
          </b-alert>

          <draggable v-else v-model="rows" tag="ul" class="list-group" handle=".draggable-task-handle" @end="handlerSiraChange">
            <BolgeItem
              v-for="(item, index) in rows"
              :key="index"
              :item="item"
              @handlerSubItemAdd="handlerNewItem($event)"
              @handlerUpdate="handlerUpdate($event)"
            />
          </draggable>
        </ul>
      </nav>

      <!-- <draggable v-else v-model="rows" tag="ul" class="list-group" handle=".draggable-task-handle" @end="handlerSiraChange">
        <BolgeItem
          v-for="(item, index) in rows"
          :key="index"
          :item="item"
          @handlerSubItemAdd="handlerNewItem($event)"
          @handlerUpdate="handlerUpdate($event)"
        />
      </draggable> -->
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <BolgeEkle
          v-else
          :updateData="updateData"
          @show="show = $event"
          @updateRefresh="handlerUpdatedData"
          @handlerClose="type = $event"
        />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { ref, watch, defineComponent, computed, onMounted, toRefs } from '@vue/composition-api';
import store from '@/store';
import BolgeEkle from './component/BolgeEkle.vue';
import BolgeItem from './component/BolgeItem.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    draggable,
    BListCard,
    BolgeEkle,
    BolgeItem,
  },
  props: {
    newAdd: [Boolean],
  },
  setup(props, cntxt) {
    const expo = {};
    const toast = useToast();
    const { newAdd } = toRefs(props);
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.collapseMenu = ref(false);
    expo.updateData = ref({});
    expo.rows = ref([]);
    expo.form = ref({
      _id: null,
      k_no: null,
      bolge_k_no: null,
      baslik_ayni: true,
      icerik: {},
      gorsel: null,
      icon: 'map-marker-alt',
      vtrin: false,
      statu: true,
    });

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          baslik: expo.defaultDil.value == dil.lang ? '' : null,
          aciklama: '',
          slug: '',
        };
      });
    };

    createIcerikDilUret();

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('bolgeListele');
      expo.rows.value = store.getters.getBolgeler;
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = (event) => {
      expo.show.value = true;
      expo.form.value.bolge_k_no = event.k_no;

      store
        .dispatch('bolgeEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            expo.updateData.value = res.data.data;
            expo.type.value = true;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    expo.handlerUpdatedData = () => {
      expo.rows.value = store.getters.getBolgeler;
    };

    expo.handlerSiraChange = () => {
      store
        .dispatch('bolgeSiraGuncelle', { bolge: expo.rows.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    onMounted(() => {
      const navListMenu = document.querySelector('.nav-list-menu');

      navListMenu.addEventListener('click', (e) => {
        const menuItemHasChildren = e.target.parentElement;
        if (e.target.getAttribute('data-toggle') == 'sub-menu') {
          if (!menuItemHasChildren.children[1].classList.contains('active')) {
            menuItemHasChildren.children[1].classList.add('active');
          } else {
            menuItemHasChildren.children[1].classList.remove('active');
          }
        }
      });
    });

    watch(expo.search, (val) => {
      if (val.length > 0) {
        const search = val.toLocaleLowerCase('TR').trim();
        let filteredData = store.getters.getBolgeler;
        filteredData = filteredData.filter((rows) => {
          return rows.icerik['tr'].baslik.toLocaleLowerCase('TR').includes(search);
        });
        expo.rows.value = filteredData;
      } else {
        expo.rows.value = store.getters.getBolgeler;
      }
    });

    watch(
      newAdd,
      (val) => {
        if (val) {
          expo.handlerNewItem({ k_no: null });
          cntxt.emit('closeEvent', false);
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
