<template>
  <li class="list-menu-item">
    <ElementItem
      class="ana-item sub-item"
      :item="item"
      @handlerSubItemAdd="$emit('handlerSubItemAdd', $event)"
      @handlerUpdate="$emit('handlerUpdate', $event)"
    />
    <ul class="list-sub-menu">
      <li class="list-sub-item" v-for="(subitem, index) in item.children" :key="index">
        <ElementItem class="sub-item" :item="subitem" @handlerUpdate="$emit('handlerUpdate', $event)" />
      </li>
    </ul>
  </li>
</template>

<script>
import ElementItem from './ElementItem.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {
    ElementItem,
  },
  props: {
    item: {
      type: Object,
      requied: true,
    },
  },
  setup(_, context) {
    const expo = {};
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
