<template>
  <b-overlay :show="show" class="w-100 h-100">
    <b-form @submit.stop.prevent="onSubmit" @reset.prevent="resetForm" autocomplete="off" class="w-100 p-2">
      <b-row>
        <b-col cols="12 pt-3" md="6">
          <b-form-group label="KDV Türü">
            <v-select
              v-model="form.kdv_turu"
              :options="kdvTurleri"
              :reduce="(sayfa) => sayfa.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3" md="6">
          <b-form-group label="KDV Oran">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="baslik"
              type="number"
              v-model="form.kdv_oran"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Ekstra Hizmet Bilgisi"
            description="Bu ürünü alanların hangi ektra hizmet satın alabileceklerini belirleyiniz."
          >
            <v-select
              v-model="form.ekstra_hizmet_k_no"
              :options="ektraHizmetler"
              :reduce="(sayfa) => sayfa.k_no"
              label="baslik"
              :clearable="false"
              multiple
              class="invoice-filter-select d-block"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3" md="2">
          <b-form-group label="Yetişkin Yaş Aralıgı">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="baslik"
              v-model="form.yas.yetiskin"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3" md="2">
          <b-form-group label="Çocuk Yaş Aralıgı">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="baslik"
              v-model="form.yas.cocuk"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3" md="2">
          <b-form-group label="Bebek Yaş Aralıgı">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="baslik"
              v-model="form.yas.bebek"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import slugify from 'slugify';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    show: [Boolean],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.form = ref({
      k_no: null,
      kdv_turu: 'dahil',
      kdv_oran: 0,
      ekstra_hizmet_k_no: [],
      yas: {
        yetiskin: '18-99',
        cocuk: '05-17',
        bebek: '00-04',
      },
    });

    expo.kdvTurleri = ref([
      {
        value: 'dahil',
        title: 'Dahil',
      },
      {
        value: 'haric',
        title: 'Haric',
      },
    ]);

    expo.ektraHizmetler = computed(() => store.getters.getEkstraHizmetler.filter((x) => x.urun_grubu.includes('transfer')));

    const handlerConfig = async () => {
      context.emit('show', true);

      await store.dispatch('ekstraHizmetListele', { statu: true, urun_grubu: ['transfer'] });

      await store.dispatch('transferAyarlarGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('transferAyarlarEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
