<template>
  <div>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th scope="col"><i class="fad fa-sort"></i></th>
          <th scope="col">#</th>
          <th scope="col">Ana Bölge</th>
          <th scope="col">Bölge</th>
          <th scope="col" class="text-right">Ortalama Süre</th>
          <th scope="col" class="text-right">Bölge Alış Saat</th>
          <th scope="col" class="text-right">
            <b-button size="sm" squared variant="primary" v-b-tooltip.hover.top="'Bölge Ata'" @click="openModal = true">
              BÖLGE ATA
            </b-button>
          </th>
        </tr>
      </thead>
      <draggable v-model="form.aktif_bolgeler" tag="tbody" handle=".draggable-task-handle">
        <tr v-for="(bolge, index) in form.aktif_bolgeler" :key="index">
          <td><i class="fad fa-sort fa-2x draggable-task-handle" style="cursor: move"></i></td>
          <th scope="row">
            {{ index + 1 }}
          </th>
          <th scope="row">{{ bolgeTitle(bolge.ana_k_no) }}</th>
          <td>{{ bolgeTitle(bolge.k_no) }}</td>
          <!-- <td>{{ bolge.icerik[default] }}</td> -->
          <td>
            <b-form-input
              v-model="bolge.sure"
              v-mask="'##:##'"
              v-b-tooltip.hover.top="'Ortalama Trasnfer Süresi'"
              size="sm"
              class="rounded-0 float-right w-50"
            />
          </td>
          <td>
            <b-form-input
              v-model="bolge.bolge_alis"
              v-b-tooltip.hover.top="'Bölgeden Misafir Kaç Saat Önce Alınacağını Belirleyiniz'"
              size="sm"
              v-mask="'##:##'"
              class="rounded-0 float-right w-50"
            />
          </td>
          <td class="text-right">
            <b-button size="sm" squared variant="danger" @click="handlerRemoveBolge(bolge)">
              <i class="fad fa-trash"></i>
            </b-button>
          </td>
        </tr>
      </draggable>
    </table>

    <b-button type="submit" block squared size="lg" variant="primary" @click="onSubmit">
      <i class="fad fa-save"></i> GÜNCELLE
    </b-button>
    <AktifBolgeEkle :form="form" :openModal="openModal" @closeModal="openModal = $event" />
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, toRefs, computed } from '@vue/composition-api';
import AktifBolgeEkle from './AktifBolgeEkle.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
export default defineComponent({
  components: { draggable, AktifBolgeEkle },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { form } = toRefs(props);
    expo.openModal = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const tumBolgeler = computed(() => store.getters.getTumBolgeler.filter((x) => x.statu == true));

    expo.bolgeTitle = computed(() => {
      return (val) => {
        const bolge = tumBolgeler.value.find((x) => x.k_no == val);

        if (bolge != undefined) {
          return bolge.icerik[expo.defaultDil.value].baslik;
        } else {
          return '';
        }
      };
    });

    expo.handlerRemoveBolge = (event) => {
      form.value.aktif_bolgeler.splice(form.value.aktif_bolgeler.indexOf(event), 1);
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('transferTarifeBolgeGuncelle', form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            context.emit('updateRefresh', res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
