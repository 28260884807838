<template>
  <div class="list-menu-item-title" :class="!item.statu && 'disabled'" data-toggle="sub-menu">
    <div class="d-flex align-items-center">
      <b-avatar
        v-if="item.urun > 0"
        size="sm"
        variant="warning"
        data-toggle="sub-menu"
        class="mr-2"
        v-b-tooltip.hover.top="'Ekli Olan Ürünler'"
      >
        {{ item.urun }}
      </b-avatar>
      <b-avatar
        v-if="subChildrenLength(item) > 0"
        size="sm"
        variant="primary"
        data-toggle="sub-menu"
        class="mr-2"
        v-b-tooltip.hover.top="'Alt Adet'"
      >
        {{ subChildrenLength(item) }}
      </b-avatar>
      <i v-if="item.children" class="fad fa-caret-down down"></i>
      <i class="fad fa-sort-up move-el draggable-task-handle" v-b-tooltip.hover.top="'Taşı'"></i>
      <div class="d-block">
        <span class="d-block font-weight-normal">{{ item.icerik[defaultDil].baslik }}</span>
      </div>
    </div>
    <b-button-group>
      <b-button
        v-if="!item.bolge_k_no"
        size="sm"
        variant="info"
        tooltip="düzenle"
        class="rounded-0"
        @click="$emit('handlerSubItemAdd', item)"
      >
        <i class="fad fa-plus"></i>
      </b-button>
      <b-button size="sm" variant="warning" tooltip="düzenle" class="rounded-0" @click="$emit('handlerUpdate', item)">
        <i class="fad fa-edit"></i>
      </b-button>
      <b-button size="sm" variant="danger" class="rounded-0" @click="handlerRemove(item.k_no)">
        <i class="fad fa-trash"></i>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Swal from 'sweetalert2';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.subChildrenLength = computed(() => {
      return (item) => {
        if (item.children != undefined) {
          return item.children.length;
        }
      };
    });

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Firmaya Ait Tüm Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('bolgeSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
            } else {
              toast.error(res.data.message, { position: 'bottom-left' });
              context.emit('show', false);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
