<template>
  <b-overlay :show="show" class="w-100 h-100">
    <div class="h-100 w-100 d-flex justify-content-start">
      <div class="b-no-list-card-body-left">
        <nav class="nav-list-menu">
          <ul class="list-menu">
            <b-alert variant="warning" show class="text-center m-3" v-if="!rows.length">
              <h4 class="alert-heading">Bilgi!</h4>
              <p>Henüz Kayıt Bulunmamaktadır.</p>
            </b-alert>

            <draggable v-else v-model="rows" tag="ul" class="list-group" handle=".draggable-task-handle" @end="handlerSiraChange">
              <TarifeBolgeItem
                v-for="(item, index) in rows"
                :key="index"
                :item="item"
                @handlerSubItemAdd="handlerNewItem($event)"
                @handlerUpdate="handlerUpdate($event)"
              />
            </draggable>
          </ul>
        </nav>
      </div>
      <div class="b-no-list-card-body-right">
        <div class="p-3">
          <b-alert variant="info" show class="text-center" v-if="!type">
            <h4 class="alert-heading">Bilgi!</h4>
            <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
          </b-alert>
          <TarifeBolgeEkle
            v-else
            :form="form"
            @show="show = $event"
            @updateRefresh="handlerUpdatedData"
            @handlerClose="type = $event"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { ref, watch, defineComponent, computed, toRefs } from '@vue/composition-api';
import store from '@/store';
import TarifeBolgeEkle from './component/TarifeBolgeEkle.vue';
import TarifeBolgeItem from './component/TarifeBolgeItem.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    draggable,
    BListCard,
    TarifeBolgeEkle,
    TarifeBolgeItem,
  },
  props: {
    newAdd: [Boolean],
  },
  setup(props, cntxt) {
    const expo = {};
    const toast = useToast();
    const { newAdd } = toRefs(props);
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.rows = ref([]);
    expo.form = ref({
      _id: null,
      k_no: null,
      icerik: {},
      aktif_bolgeler: [],
      icon: null,
      gorseller: [],
      statu: true,
    });

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          baslik: expo.defaultDil.value == dil.lang ? 'Yeni Tarife Bolge' : null,
          aciklama: '',
          slug: '',
        };
      });
    };

    createIcerikDilUret();

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('transferTarifeBolgeListele');
      await store.dispatch('bolgeListele');
      expo.rows.value = store.getters.getTransferTarifeBolgeleri;
      expo.show.value = false;
    };

    handlerGetData();

    const handlerResetForm = () => {
      expo.form.value = {
        _id: null,
        k_no: null,
        icerik: {},
        aktif_bolgeler: [],
        icon: null,
        gorseller: [],
        statu: true,
      };

      createIcerikDilUret();
    };

    expo.handlerNewItem = (event) => {
      expo.show.value = true;
      handlerResetForm();
      store
        .dispatch('transferTarifeBolgeEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            expo.form.value = res.data.data;
            expo.type.value = true;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.form.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    expo.handlerUpdatedData = () => {
      expo.rows.value = store.getters.getTransferTarifeBolgeleri;
    };

    expo.handlerSiraChange = () => {
      store
        .dispatch('transferTarifeBolgeSiraGuncelle', { havaliman: expo.rows.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    watch(expo.search, (val) => {
      if (val.length > 0) {
        const search = val.toLocaleLowerCase('TR').trim();
        let filteredData = store.getters.getTransferTarifeBolgeleri;
        filteredData = filteredData.filter((rows) => {
          return rows.icerik['tr'].baslik.toLocaleLowerCase('TR').includes(search);
        });
        expo.rows.value = filteredData;
      } else {
        expo.rows.value = store.getters.getTransferTarifeBolgeleri;
      }
    });

    watch(
      newAdd,
      (val) => {
        if (val) {
          expo.handlerNewItem({ k_no: null });
          cntxt.emit('closeEvent', false);
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
