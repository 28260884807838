<template>
  <b-tabs content-class="mt-3">
    <b-tab title="İçerik" active>
      <Icerik
        :form="form"
        @show="$emit('show', $event)"
        @updateRefresh="$emit('updateRefresh', $event)"
        @handlerClose="$emit('handlerClose', $event)"
      />
    </b-tab>
    <b-tab title="Fotoğraflar">
      <Fotograflar :form="form" />
    </b-tab>
  </b-tabs>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed } from '@vue/composition-api';
import store from '@/store';
import Icerik from './TheIcerik.vue';
import Fotograflar from './TheFotograflar.vue';
export default defineComponent({
  components: {
    vSelect,
    Icerik,
    Fotograflar,
  },
  props: {
    form: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
